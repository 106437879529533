<template>
  <v-container>
    <h3>Liste des utilisateurs</h3>

    <users-table></users-table>
  </v-container>
</template>

<script>
import UsersTable from '@/components/UsersTable.vue';

export default {
  name: 'users-view',

  components: {
    UsersTable
  },

  methods: {
    loadBreadcrumbsData: function(/*$event*/) {

    }
  },

  mounted: function() {
    
  }
}
</script>