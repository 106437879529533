<template>
  <v-container fluid><!--  fill-height -->
    <v-alert type="warning" icon="mdi-traffic-cone">
      En construction : utiliser l'utilisateur "<strong>test</strong>" avec le mot de passe "<strong>test</strong>"
    </v-alert>

    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="login" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Connexion</h3>
            </v-card-title>
            <v-card-text>

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                icon="mdi-alert-circle"
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model.trim="username"
                autofocus
                label="Nom d'utilisateur"
                hide-details
                prepend-icon="mdi-account-circle"
                ref="username"
                class="shrink"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Mot de passe"
                hide-details
                prepend-icon="mdi-lock"
                class="shrink"
                variant="underlined"
                @click:append="show = !show"
              ></v-text-field>

              <v-checkbox
                v-model="remember"
                label="Rester connecter"
              ></v-checkbox>

              <a @click.prevent="goLostPasswordPage" color="primary">
                Mot de passe oublié ?
              </a>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goRegisterPage" color="primary" text>
                S'enregistrer
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success" variant="elevated">
                Se connecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import GeneralException from '@/utils/exception.js';

export default {
  beforeRouteUpdate(/*to, from, next*/) {
    // autofocus username field on route change
    this.$refs.username.focus();
  },

  setup: function() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data: function() {
    return {
      error: false,
      errorText: '',
      password: '',
      remember: false,
      show: false,
      username: ''
    };
  },

  methods: {
    login: async function() {
      //this.$refs.form.validate();
      // initialize error alert
      this.error = false;

      const { username, password, remember } = this;

      try {
        await this.authStore.loginRequest({ username, password, remember }); // @todo =this
        console.log('current query string:', this.$route.query.redir);
        if (this.$route.query.redir != null) {
          this.$router.push(this.$route.query.redir);
        } else {
          this.$router.push('/');
        }
        /*const client_id = this.$route.query.client_id; // oauth client_id hint

        const response = await this.authStore.loginRequest({ username, password, remember, client_id }); // @todo =this

        if (typeof response.data.oauth !== 'undefined') {
          const consent = response.data.oauth.consent;
          console.log('consent:', consent);

          // do not redirect to consent page
          let redir = '/';
          if (this.$route.query.redir !== null) {
            const url = new URL(this.$route.query.redir, 'http://no-host.tld');
            redir = url.searchParams.get('redir');
            console.log('do not redir to consent:', redir);
            window.location.href = redir;
          }

          this.$router.push(redir);
        } else {
          console.log('current query string:', this.$route.query.redir);
          if (this.$route.query.redir != null) {
            this.$router.push(this.$route.query.redir);
          } else {
            this.$router.push('/');
          }
        }*/
      } catch(err) {
        if (err instanceof GeneralException) {
          // get server response
          this.errorText = err.message;
          this.error = true;
        } else {
          this.errorText = 'Erreur inconnue';
          this.error = true;
        }
      }
    },
    goLostPasswordPage: function() {
      this.$router.push('/lost-password');
    },
    goRegisterPage: function() {
      this.$router.push('/register');
    }
  }
};
</script>