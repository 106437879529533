<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="submitForm" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Redéfinition du mot de passe</h3>
            </v-card-title>
            <v-card-text v-if="!success">

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                autofocus
                label="Mot de passe"
                hide-details
                prepend-icon="mdi-lock"
                class="shrink"
                variant="underlined"
                @click:append="show = !show"
              ></v-text-field>

              <v-text-field
                v-model="passwordRepeat"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                label="Mot de passe (vérification)"
                hide-details
                prepend-icon="mdi-lock"
                class="shrink"
                variant="underlined"
                @click:append="show2 = !show2"
              ></v-text-field>

            </v-card-text>
            <v-card-text v-else>
              <v-alert border="left"
                type="success"
              >Mot de passe redéfini avec succés</v-alert>

              <p>
                Vous pouvez vous connecter avec votre nouveau mot de passe.
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goLoginPage" color="primary" text>
                Se connecter
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success" v-if="!success">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAuthStore } from '@/stores/auth';

export default {
  setup: function() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data: function() {
    return {
      error: false,
      errorText: '',
      password: '',
      passwordRepeat: '',
      selector: '',
      show: false,
      show2: false,
      success: false,
      token: ''
    };
  },

  mounted: function() {
    // get selector and token from the query string
    if (this.$route.query.selector) {
      this.selector = this.$route.query.selector;
    }
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
  },

  methods: {
    submitForm: function() {
      //this.$refs.form.validate();
      
      // initialize error alert
      this.error = false;

      const self = this;
      const { password, passwordRepeat, selector, token } = this;
      //const selector = this.selector; // @todo on previous line?
      //const token = this.token; // @todo on previous line?

      // passwords are differents
      if (password != passwordRepeat) {
        this.errorText = 'Les mots de passe ne sont pas identiques !';
        this.error = true;

        // reset form
        this.password = this.passwordRepeat = '';

        return;
      }

      this.authStore.resetLostPasswordRequest({ password, selector, token })
        .then(function() {
          self.success = true; // success notification
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },
    goLoginPage: function() {
      this.$router.push('/login');
    }
  }
};
</script>