<template>
  <v-dialog
    v-model="dialogOpened"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>{{mdiHelpCircle}}</v-icon>
        Aide
      </v-btn>
    </template>

    <v-card>
      <v-toolbar
        v-if="$vuetify.breakpoint.xsOnly"
        color="primary"
        style="margin-bottom: 20px;"
      >
        <v-btn
          color="white"
          icon
          @click="dialogOpened = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>

        <v-toolbar-title class="white--text">
          Aide
        </v-toolbar-title>
      </v-toolbar>

      <v-card-title v-if="$vuetify.breakpoint.smAndUp">
        Aide
      </v-card-title>

      <v-card-text>
        <slot name="default">En construction...</slot>
      </v-card-text>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialogOpened = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiHelpCircle } from '@mdi/js';

export default {
  data: function() {
    return {
      dialogOpened: false,

      // icons
      mdiClose: mdiClose,
      mdiHelpCircle: mdiHelpCircle
    }
  }
}
</script>