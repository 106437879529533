import VueRouter from 'vue-router';

import HomeView from "@/views/HomeView.vue";

import { useAuthStore } from '@/stores/auth';
import Clients from '@/views/admin/Clients.vue';
import ConfirmEmail from '@/views/auth/ConfirmEmail.vue';
import Consent from '@/views/auth/Consent.vue';
import Login from '@/views/auth/Login.vue';
import LostPassword from '@/views/auth/LostPassword.vue';
import Profile from '@/views/Profile.vue';
import Register from '@/views/auth/Register.vue';
import ResetLostPassword from '@/views/auth/ResetLostPassword.vue';
import Users from '@/views/admin/Users.vue';

// navigation guards
const ifNotAuthenticated = (to, from, next) => {
  const store = useAuthStore();
  if (!store.getIsAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  const store = useAuthStore();
  if (store.getIsAuthenticated) {
    next();
  } else {
    next({
      path: '/login',
      query: { // passing requested page path to redirect after login
        redir: to.fullPath
      }
    });

    // close drawer properly if login page is already opened
    //store.commit('toggleDrawerState', false);
  }
};

const router = new VueRouter({
  // @todo do server configuration before using history mode
  // @see https://stackoverflow.com/a/51340156
  //mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/AboutView.vue"),
    },

    {
      path: '/login',
      name: 'login',
      components: {
        default: Login
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/lost-password',
      name: 'lost-password',
      components: {
        default: LostPassword
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      components: {
        default: ResetLostPassword
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/register',
      name: 'register',
      components: {
        default: Register
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      components: {
        default: ConfirmEmail
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/profile',
      name: 'profile',
      components: {
        default: Profile
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/consent',
      name: 'consent',
      components: {
        default: Consent
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users/developers/clients',
      name: 'clients',
      components: {
        default: Consent
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users/developers/clients/new',
      name: 'new-client',
      components: {
        default: Consent
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users/grants',
      name: 'user-grants',
      components: {
        default: Consent
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      components: {
        default: Users
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/clients',
      name: 'admin-clients',
      components: {
        default: Clients
      },
      beforeEnter: ifAuthenticated
    },
  ],
});

export default router;
