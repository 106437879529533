const GeneralException = function(message, code, details) {
  if (typeof details === 'undefined') {
    details = null;
  }

  this.details = details;

  if (typeof code === 'undefined') {
    code = null;
  }

  this.code = code;

  this.message = message;
  this.name = 'GeneralException';
  console.log(/*message, */code, details);
}

export default GeneralException;