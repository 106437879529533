import http from '@/utils/http';

class ScopeDataService {
  getAll(data) {
    return http.get(`/oauth/scopes`, { params: data });
  }

  get(id) {
    return http.get(`/oauth/scopes/${id}`);
  }

  create(data) {
    return http.post(`/oauth/scopes`, data);
  }

  update(id, data) {
    return http.put(`/oauth/scopes/${id}`, data);
  }

  delete(id) {
    return http.delete(`/oauth/scopes/${id}`);
  }

  findByName(name) {
    return http.get(`/oauth/scopes?name=${name}`);
  }
}

export default new ScopeDataService();