<template>
  <v-dialog
    @click:outside="close"
    :value="value"
    max-width="500"
  >
    <v-card>
      <v-toolbar
        color="primary"
        style="margin-bottom: 20px;"
      >
        <v-btn
          color="white"
          icon
          @click="close"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>

        <v-toolbar-title class="white--text">
          <slot name="title">Gestion des rôles</slot>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          color="white"
          text
          @click="close"
        >
          Fermer
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col>
            Les rôles suivant sont attribués à l'utilisateur :
          </v-col>
        </v-row>

        <v-row
          v-if="!loading"
          align="center"
          justify="start"
        >
          <v-col
            v-for="(selection, i) in selections"
            :key="selection"
            class="shrink pa-1"
          >
            <v-chip
              close
              color="primary"
              @click:close="removeRole(i)"
            >
              {{ selection }}
            </v-chip>
          </v-col>

          <v-col
            v-if="!allSelected"
            cols="12"
          >
            <v-text-field
              ref="search"
              v-model="search"
              dense
              full-width
              hide-details
              label="Filtrer..."
              single-line
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider v-if="!allSelected"></v-divider>

        <v-list>
          <template v-for="item in availableRoles">
            <v-list-item
              v-if="!selected.includes(item)"
              :key="item"
              :disabled="loading"
              @click="addRole(item)"
            >
              <v-list-item-title>{{item}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserDataService from '@/services/UserDataService';
import { mdiClose } from '@mdi/js';

export default {
  props: {
    user: {
      type: Number,
      required: true
    },
    value: Boolean
  },

  data: function() {
    return {
      // @todo get from API /api/v1.0/roles
      items: [
        'ADMIN',
        'AUTHOR',
        'COLLABORATOR',
        'CONSULTANT',
        'CONSUMER',
        'CONTRIBUTOR',
        'COORDINATOR',
        'CREATOR',
        'DEVELOPER',
        'DIRECTOR',
        'EDITOR',
        'EMPLOYEE',
        'MAINTAINER',
        'MANAGER',
        'MODERATOR',
        'PUBLISHER',
        'REVIEWER',
        'SUBSCRIBER',
        'SUPER_ADMIN',
        'SUPER_EDITOR',
        'SUPER_MODERATOR',
        'TRANSLATOR'
      ],

      loading: true,
      search: '',
      selected: [],

      // icons
      mdiClose: mdiClose
    }
  },

  computed: {
    allSelected: function() {
      return this.selected.length === this.items.length;
    },

    availableRoles: function() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter(item => {
        const text = item.toLowerCase();

        return text.indexOf(search) > -1;
      })
    },

    selections: function() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selected: function() {
      this.search = '';
    }
  },

  mounted: function() {
    UserDataService.getRoles(this.user)
      .then((response) => {
        // then injecting the result to datatable parameters
        this.loading = false;
        this.selected = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    addRole: function(role) {
      // send request
      UserDataService.addRole(this.user, role)
        .then((/* response */) => {
          // add chip
          this.selected.push(role);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    removeRole: function(index) {
      // get role
      const role = this.selected[index];

      // send request
      UserDataService.removeRole(this.user, role)
        .then((/* response */) => {
          // remove chip
          this.selected.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    close: function() {
      this.$emit('close');
    }
  }
}
</script>