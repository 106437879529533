<template>
  <v-container fluid><!-- fill-height -->
    <v-alert type="warning" icon="mdi-traffic-cone">
      En construction
    </v-alert>

    <v-list>
      <v-list-item v-if="!authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/login">Se connecter</router-link>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="!authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/register">Créer un nouveau compte</router-link>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-account-details</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/profile">Profil</router-link> (user: self)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-account-key</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/tokens">Gestion des jetons</router-link> (user: self)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-application-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/admin/clients">Applications</router-link> (admin: all, dev: owned)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-hand-front-right-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/admin/scopes">Gestion des scopes</router-link> (admin: all)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/admin/users">Utilisateurs</router-link> (admin: all)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated">
        <v-list-item-icon>
          <v-icon>mdi-account-question</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/consent">Fenêtre autorisation</router-link> (consent test shortcut)
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="authStore.getIsAuthenticated" @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <a href="javascript:void(0);">Déconnexion</a>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <pre v-if="authStore.getIsAuthenticated">{{userProfile}}</pre>
  </v-container>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';

export default {
  setup: function() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    return { authStore, userStore };
  },

  computed: {
    userProfile() {
      return JSON.stringify(this.userStore.getUserProfile, null, 4);
    }
  },

  methods: {
    logout: async function() {
      // initialize error alert
      this.error = false;

      try {
        await this.authStore.logoutRequest();
      } catch(error) {
        this.errorText = error.message;
        // get server response
        if (error.response.data && error.response.data.message) {
          this.errorText = error.response.data.message;
        }
        this.error = true;
      }
    }
  }
};
</script>