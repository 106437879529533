<template>
  <div>

    <v-data-table
      :headers="computedHeaders"
      :items="users"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1]
      }"
      :items-per-page="50"
      multi-sort
      class="users-table elevation-1"
    >

      <template v-slot:[`item.cell.name`]="{ item }">
        <strong v-if="item.cell">{{item.cell.name}}</strong>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <strong>{{item.username}}</strong>
      </template>

      <template v-slot:[`item.verified`]="{ item }">
        <v-icon v-if="item.verified">{{mdiCheck}}</v-icon>
      </template>

      <template v-slot:[`item.last_login`]="{ item }">
        {{item.last_login ? timestampToDate(item.last_login) : ''}}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Utilisateurs</v-toolbar-title>
          <v-divider
            v-if="$vuetify.breakpoint.smAndUp"
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <HelpDialog>
            <p>En construction...</p>
          </HelpDialog>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.stop="createItem()"
          >
            Ajouter
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editRoles(item)"
        >
          {{mdiAccountGroup}}
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{mdiPencil}}
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          {{mdiDelete}}
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Recharger
        </v-btn>
      </template>

    </v-data-table>

    <!-- create/edit dialog -->
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card>
        <v-toolbar
          v-if="$vuetify.breakpoint.xsOnly"
          color="primary"
          style="margin-bottom: 20px;"
        >
          <v-btn
            color="white"
            icon
            @click="close"
          >
            <v-icon>{{mdiClose}}</v-icon>
          </v-btn>

          <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            color="white"
            text
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-toolbar>

        <v-card-title v-if="$vuetify.breakpoint.smAndUp">
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :value="editedItem.username"
                  label="Nom d'utilisateur"
                  disabled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :value="editedItem.email"
                  label="Adresse email"
                  disabled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.phone"
                  label="Téléphone (mobile)"
                  autofocus
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.password"
                  label="Mot de passe"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- user roles dialog -->
    <roles-dialog
      v-if="dialogRolesState"
      @close="closeRoles"
      :user="editedItem.id"
      :value="dialogRolesState"
    >
      <template v-slot:title>
        Gestion des rôles
      </template>
      <!--<template v-slot:default>
        <p>L'utilisateur supprimé ne pourra pas être restauré.</p>
        <p>Il lui sera impossible de se connecter à cette application, et,
          par voie de conséquence, à toutes les applications connectées.</p>
      </template>-->
    </roles-dialog>

    <!-- delete dialog -->
    <delete-dialog
      @close="closeDelete"
      @confirm="confirmDelete"
      :value="dialogDeleteState"
    >
      <template v-slot:title>
        Supprimer l'utilisateur ?
      </template>
      <template v-slot:default>
        <p>L'utilisateur supprimé ne pourra pas être restauré.</p>
        <p>Il lui sera impossible de se connecter à cette application, et,
          par voie de conséquence, à toutes les applications connectées.</p>
      </template>
    </delete-dialog>

  </div>
</template>

<script>
//import Alert from '@/components/Alert';
import DeleteDialog from '@/components/Base/DeleteDialog';
import HelpDialog from '@/components/Base/HelpDialog';
import RolesDialog from '@/components/UsersTable/RolesDialog';
import UserDataService from '@/services/UserDataService';
import { mdiAccountGroup, mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from '@mdi/js';
//import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import dateFormat from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

export default {
  name: 'users',

  /*setup: function() {
    const breadcrumbsStore = useBreadcrumbsStore();
    return { breadcrumbsStore };
  },*/

  components: {
    //Alert,
    DeleteDialog,
    HelpDialog,
    RolesDialog
  },

  data: function() {
    return {
      page: 0,
      totalItems: 0,
      totalPages: 0,
      sortBy: 'id',
      sortDesc: false,
      locale: 'fr-FR',
      users: [],
      loading: true,
      options: {},

      dialog: false,
      dialogDeleteState: false,
      dialogRolesState: false,
      headers: [
        //{ text: 'ID', value: 'id' },
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'username',
          mobile: true
        },
        { text: 'Email', value: 'email' },
        { text: 'Téléphone', value: 'profile.phone' },
        { text: 'Vérifié', value: 'verified' },
        { text: 'Roles', value: 'roles' },
        { text: 'Dernière connexion', value: 'last_login' },
        { text: 'Actions', value: 'actions', sortable: false, mobile: true }
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        username: '',
        email: '',
        phone: '',
        gicasa_name: ''
      },
      defaultItem: {
        id: 0,
        username: '',
        email: '',
        phone: '',
        gicasa_name: ''
      },

      // icons
      mdiAccountGroup: mdiAccountGroup,
      mdiCheck: mdiCheck,
      mdiClose: mdiClose,
      mdiDelete: mdiDelete,
      mdiPencil: mdiPencil,
      mdiPlus: mdiPlus
    };
  },

  computed: {
    computedHeaders: function() {
      // not mobile: display all headers
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.headers;
      }

      // mobile: display a subset of headers (these with property "mobile" set to true)
      return this.headers.filter(function(header) {
        if (typeof header.mobile === 'undefined') return false;
        return header.mobile;
      });  
    },
    formTitle: function() {
      return this.editedIndex === -1 ? 'Ajouter' : 'Modifier';
    },
    // @todo B
    /*isFabClicked: function() {
      return this.$store.getters['fab/isClicked'];
    }*/
  },

  watch: {
    /*isFabClicked: function(action) {
      // prevent incorrect fab action
      if (action === 'create-product') {
        // reset click state
        this.$store.dispatch('fab/click', false);

        // call linked method
        this.createItem();
      }
    },*/
    dialog: function(visible) {
      visible || this.close();
    },
    dialogDeleteState: function(visible) {
      visible || this.closeDelete();
    },
    dialogRolesState: function(visible) {
      visible || this.closeRoles();
    },
    options: {
      handler: function() {
        // reactive pagination
        this.readDataFromAPI()
      }
    },
    deep: true
  },

  // this will trigger in the onReady State
  mounted: function() {
    // breadcrumbs
    /*this.breadcrumbsStore.setBreadcrumbs([
      {
        text: 'Accueil',
        disabled: false,
        href: this.$router.resolve('/').href
      },
      {
        text: 'Gestion du couplage Gicasa',
        disabled: true,
        href: this.$router.resolve('/gicasa-mapper-management').href
      }
    ]);

    // set fab context
    this.$store.dispatch('fab/set', {
      action: 'create-product',
      color: 'primary',
      icon: 'mdiPlus'
    });*/
  },

  methods: {
    initialize: function() {
      this.readDataFromAPI(); // read users
    },

    createItem: function() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },

    editItem: function(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      /*this.editedItem = Object.assign({}, {
        id: item.id,
        cell_id: item.cell_id,
        site_id: item.warehouse.site_id,
        warehouse_id: item.warehouse.id,
        gicasa_id: item.stock.cell_gicasa_id,
        gicasa_name: item.stock.cell_name
      });*/
      if (item.cell)
        this.editedItem.warehouse_id = item.cell.warehouse_id;
      console.log(this.editedItem);
      this.dialog = true;
    },

    editRoles: function(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRolesState = true;
    },

    deleteItem: function(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteState = true;
    },

    confirmDelete: function() {
      // @todo
      const id = this.users[this.editedIndex].id;

      UserDataService.delete(id)
        .then((response) => {
          console.log(response.data);
          this.users.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    close: function() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete: function() {
      this.dialogDeleteState = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeRoles: function() {
      this.dialogRolesState = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save: function() {
      const data = this.editedItem;

      // handle NULL value in gicasa_id
      if (data.gicasa_id === '') {
        data.gicasa_id = null;
      }

      if (this.editedIndex > -1) {
        // @todo product id is in this.editedItem ?
        const id = this.users[this.editedIndex].id;
        UserDataService.update(id, data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            Object.assign(this.users[this.editedIndex], this.editedItem);
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        UserDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            this.readDataFromAPI();
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // reading data from API method.
    readDataFromAPI: function() {
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      console.log(this.options);
      const pageNumber = page - 1;
      const offset = pageNumber * itemsPerPage;

      var sortQuery = '';
      if (sortBy.length > 0) {
        // qsort=+author,-datepublished
        var sortOrderSymbol = ''
        for (var i = 0; i < sortBy.length; i++) {
          sortOrderSymbol = sortDesc[i] ? '-' : '+';
          sortQuery += sortOrderSymbol + sortBy[i] + ',';
        }
        sortQuery = sortQuery.slice(0, -1);
      }

      let data = {
        limit: itemsPerPage,
        page: pageNumber,
        offset: offset
      };

      if (sortQuery) {
        data.sort = sortQuery;
      }

      UserDataService.getAll(data)
        .then((response) => {
          // then injecting the result to datatable parameters.
          this.loading = false;
          this.users = response.data;
          this.totalItems = parseInt(response.headers['x-total-count']);
          this.totalPages = parseInt(response.headers['x-total-pages']);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toggleOrder: function() {
      if (this.sortDesc === 'asc') {
        this.sortDesc = 'desc';
      }
    },

    nextSort: function() {
      let index = this.headers.findIndex(h => h.value === this.sortBy);
      index = (index + 1) % this.headers.length;
      this.sortBy = this.headers[index].value;
    },

    timestampToDate: function(ts) {
      const date = fromUnixTime(ts);
      return dateFormat(date, 'dd/MM/yyyy HH:mm:ss');
    }
  }
};
</script>

<style scoped>
/* keep footer on bottom */
.v-data-table--mobile.v-data-table .v-data-footer {
  position: fixed;
  bottom: 56px; /* @todo bottom bar */
  width: 100%;
}

/* mobile mode format */
.v-data-table.users-table >>> .v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(even) {
  background: hsl(122, 38%, 87%);
  border: 1px solid #a4d5b1;
  display: block;
  margin: 5px;
}
.v-data-table.users-table >>> .v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(odd) {
  background: hsl(122, 38%, 94%);
  border: 1px solid #a4d5b1;
  display: block;
  margin: 5px;
}
.v-data-table.users-table >>> .v-data-table__wrapper .v-data-table__mobile-row {
  border-bottom: none;
}
.v-data-table.users-table >>> .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td.v-data-table__mobile-row:last-child,
.v-data-table.users-table >>> .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th.v-data-table__mobile-row:last-child {
  border-bottom: none;
}
</style>