<template>
  <v-app>
    <v-app-bar
      app
      color="#4caf50"
      dark
      shrink-on-scroll
      src="https://picsum.photos/id/107/1920/1080"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgb(209, 255, 214, 0.4), rgba(128, 208, 199, 0)"
        ></v-img>
      </template>

      <div class="d-flex align-center">
        <v-img
          alt="Logo Terre Atlantique"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="110"
        />

        <v-toolbar-title class="d-none d-sm-flex">Connect</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
//import AppProgressBar from '@/components/AppProgressBar';
//import UserMenu from '@/components/UserMenu';

export default {
  name: 'App',

  /*components: {
    AppProgressBar,
    AppSnackbar,
    NavigationSidebar,
    UserMenu
  },

  data: function() {
    return {
      loading: false,
      loadingSnackbar: false,
      snackbarText: 'Chargement...'
    };
  },

  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit('toggleDrawerState', v);
      }
    },
    buttonText: function() {
      return !this.$vuetify.theme.dark ? 'weather-night' : 'weather-sunny';
    }
  },*/

  created: function() {
    // autologin on load
    const store = useAuthStore();
    store.autoLogin();
  }/*,

  methods: {
    toggleTheme: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }*/
};
</script>