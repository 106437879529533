import Vue from 'vue';
import VueRouter from 'vue-router';
import { createPinia, PiniaVuePlugin } from 'pinia';
//import VueCompositionAPI from '@vue/composition-api';
import vuetify from '@/plugins/vuetify';
import App from '@/App.vue';
import router from '@/router';

// @todo use router in pinia
// @see https://stackoverflow.com/questions/70681667/cant-use-vue-router-and-pinia-inside-asingle-a-store
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);

//Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
