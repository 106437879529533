import { defineStore } from 'pinia';
//import { useAuthStore } from '@/stores/auth';
import AuthDataService from '@/services/AuthDataService';
import UserDataService from '@/services/UserDataService';
//import Vue from 'vue';

export const useUserStore = defineStore({
  id: 'user',

  state: () => ({
    profile: {},
    status: ''
  }),

  getters: {
    getUserProfile(state) {
      return state.profile;
    },
    isUserProfileLoaded(state) {
      return !!state.profile.name;
    }
  },

  actions: {
    setProfile(data) {
      this.profile = data;
    },

    async userRequest() {
      this.status = 'loading';

      // returns cached profile data if exists
      let profile = this.profile;
      if (profile) {
        console.log('user profile:', JSON.parse(JSON.stringify(profile)));

        if (Object.keys(profile).length !== 0) { // is not empty?
          console.log('not empty profile!');
          this.profile = profile;
          this.status = 'success';
          return profile;
        }
      }

      // no cached profile data, so request to the server
      try {
        const response = await AuthDataService.getCurrentUser();
        console.log(response.data);
        this.profile = response.data.user;
        this.status = 'success';
        return response.data.user;
      } catch(err) {
        console.log('USER.JS', err);
        this.status = 'error'; // @todo never set to true...
        // if response is unauthorized, logout
        /*if (process.env.NODE_ENV === 'production') {
          // @todo
          //dispatch('logoutRequest'); // @see auth module
          const authStore = useAuthStore();
          authStore.logoutRequest();
          //return err;
        }*/

        return err;
      }
    },

    async updateUserProfileRequest(data) {
      //commit('SET_AUTH_STATUS', 'loading');

      const userId = data.id;
      delete data.id;

      try {
        const response = await UserDataService.updateUserProfile(userId, data);
        console.log(response.data);
        //commit('SET_AUTH_STATUS', 'success');
        // force profile reloading
        this.profile = {};
        this.userRequest(); // @todo await? error handling?
        return response;
      } catch(err) {
        console.log(err);
        console.log(err.response.data.message);
        //commit('SET_AUTH_STATUS', 'error');
        return err;
      }
    }
  }
});
/*
const mutations = {
  SET_USER_PROFILE: (state, data) => {
    Vue.set(state, 'profile', data);
  },
  SET_USER_STATUS: (state, status) => {
    state.status = status;
  }
};*/