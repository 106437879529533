<template>
  <v-container>
    <h3>Liste des clients</h3>

    <clients-table></clients-table>
  </v-container>
</template>

<script>
import ClientsTable from '@/components/ClientsTable.vue';

export default {
  name: 'clients-view',

  components: {
    ClientsTable
  },

  methods: {
    loadBreadcrumbsData: function(/*$event*/) {

    }
  },

  mounted: function() {
    
  }
}
</script>