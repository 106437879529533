<template>
  <v-dialog
    @click:outside="close"
    :value="value"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="500"
  >
    <v-card>
      <v-toolbar
        v-if="$vuetify.breakpoint.xsOnly"
        color="error"
        style="margin-bottom: 20px;"
      >
        <v-btn
          color="white"
          icon
          @click="close"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>

        <v-toolbar-title class="white--text">
          <slot name="title">Supprimer ?</slot>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          color="white"
          text
          @click="confirm"
        >
          Supprimer
        </v-btn>
      </v-toolbar>

      <v-card-title v-if="$vuetify.breakpoint.smAndUp">
        <slot name="title">Supprimer ?</slot>
      </v-card-title>

      <v-card-text>
        <slot name="default">En construction...</slot>
      </v-card-text>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close">Annuler</v-btn>
        <v-btn color="error" text @click="confirm">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
  props: {
    value: Boolean
  },

  data: function() {
    return {
      // icons
      mdiClose: mdiClose
    }
  },

  methods: {
    close: function() {
      this.$emit('close');
    },

    confirm: function() {
      this.$emit('confirm');
    }
  }
}
</script>