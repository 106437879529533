<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="register" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Nouveau compte</h3>
            </v-card-title>
            <v-card-text>

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model.trim="username"
                autofocus
                label="Nom d'utilisateur"
                hide-details
                prepend-icon="mdi-account-circle"
                class="shrink"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                v-model.trim="email"
                label="Adresse email"
                hide-details
                prepend-icon="mdi-at"
                class="shrink"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Mot de passe"
                hide-details
                prepend-icon="mdi-lock"
                class="shrink"
                variant="underlined"
                @click:append="show = !show"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goLoginPage" color="primary" text>
                Se connecter
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success">
                Créer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAuthStore } from '@/stores/auth';

export default {
  setup: function() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data: function() {
    return {
      error: false,
      errorText: '',
      email: '',
      password: '',
      show: false,
      username: ''
    };
  },

  methods: {
    register: function() {
      //this.$refs.form.validate();
      // initialize error alert
      this.error = false;

      const self = this;
      const { username, email, password } = this;
      this.authStore.registerRequest({ username, email, password }) // @todo =this
        .then(function() {
          self.$router.push('/');
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },
    goLoginPage: function() {
      this.$router.push('/login');
    }
  }
};
</script>