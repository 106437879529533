import axios from 'axios';

// use proxy during development
// @see vue.config.js
let apiURL = '/api/v1.0';

// use absolute url in production
if (process.env.NODE_ENV === 'production') {
  apiURL = 'https://connect.terreatlantique.com/api/v1.0'; // @todo in configuration file
}

// CORS with credentials
axios.defaults.withCredentials = true; // @todo

// default request parameters
const http = axios.create({
  baseURL: apiURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default http;