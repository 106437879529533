//import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/user';
import AuthDataService from '@/services/AuthDataService';
import GeneralException from '@/utils/exception.js';

export const useAuthStore = defineStore({
  id: 'auth',

  state: () => ({
    status: '',
    token: /*localStorage.getItem('user-token') ||*/ ''
    //hasLoadedOnce: false
  }),

  getters: {
    getAuthStatus(state) {
      return state.status;
    },
    getIsAuthenticated(state) {
      return !!state.token;
    }
  },

  actions: {
    async autoLogin() {
      this.status = 'loading';
      console.log('[auth] initialize auto-login');

      try {
        const userStore = useUserStore();
        const response = await userStore.userRequest();
        this.token = response.token;
        this.status = 'success';

        return response;
      } catch(err) {
        return err; // @todo
      }
    },
  
    async loginRequest(user) {
      this.status = 'loading';

      try {
        const response = await AuthDataService.login(user);

        let userData = response.data.user;
        localStorage.setItem('user-token', userData.token);

        // Here set the header of your ajax library to the token value.
        // example with axios
        // axios.defaults.headers.common['Authorization'] = data.token
        AuthDataService.setToken(userData.token); // @todo
        this.token =  userData.token;
        this.status = 'success';

        const userStore = useUserStore();
        userStore.userRequest();

        return response;
      } catch(err) {
        this.status = 'error';
        localStorage.removeItem('user-token');
        throw new GeneralException(err.response.data.message, 'login-failed', err);
      }
    },
  
    async registerRequest(user) {
      this.status = 'loading';

      try {
        const response = await AuthDataService.register(user);
        console.log(response.data);
        this.status = 'success';

        const userStore = useUserStore();
        userStore.userRequest();

        return response;
      } catch(err) {
        console.log(err.response.data.message);
        this.status = 'error';
        return err;
      }
    },
  
    async confirmEmailRequest(data) {
      this.status = 'loading';
  
      try {
        const response = await AuthDataService.confirmEmail(data);
        console.log(response.data);
        this.status = 'success';

        const userStore = useUserStore();
        userStore.userRequest();

        return response;
      } catch(err) {
        console.log(err.response.data.message);
        this.status = 'error';
        return err;
      }
    },
  
    async sendResetPasswordLinkRequest(data) {
      this.status = 'loading';
  
      try {
        const response = await AuthDataService.sendResetPasswordLink(data);
        console.log(response.data);
        this.status = 'success';
//        const userStore = useUserStore();
//        userStore.userRequest();
        return response;
      } catch(err) {
        console.log(err.response.data.message);
        this.status = 'error';
        return err;
      }
    },
  
    async resetLostPasswordRequest(data) {
        this.status = 'loading';
  
        try {
          const response = await AuthDataService.resetLostPassword(data);
          console.log(response.data);
          this.status = 'success';
//        const userStore = useUserStore();
//        userStore.userRequest();
          return response;
        } catch(err) {
          console.log(err.response.data.message);
          this.status = 'error';
          return err;
        }
    },
  
    async logoutRequest() {
      this.status = 'loading';

      try {
        console.log('[auth] logout request');
        const response = await AuthDataService.logout();

        const userStore = useUserStore();
        userStore.setProfile({});
        localStorage.removeItem('user-token');
  
        this.token = '';
        this.status = 'success';

        return response;
      } catch(err) {
        return err; // @todo
      }
    },

    async consentRequest(choice) {
      this.status = 'loading';

      try {
        const response = await AuthDataService.consent(choice);
        console.log(response.data);

        //let userData = response.data.user;

        this.status = 'success';

        return response;
      } catch(err) {
        console.log(err.request);
        this.status = 'error';
        return err;
      }
    }
  }
});
