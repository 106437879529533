<template>
  <v-data-table
    :headers="headers"
    :items="clients"
    :items-per-page="50"
    :loading="loading"
    loading-text="Chargement..."
    v-bind:options.sync="options"
    :server-items-length="totalItems"
    v-bind:sort-by.sync="sortBy"
    v-bind:sort-desc.sync="sortDesc"
  >
    <!-- row format -->
    <template v-slot:[`item.name`]="{ item }">
      <router-link :to="'/admin/clients/' + item.id">{{item.name}}</router-link>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <span style="color: grey;">{{item.id}}</span><!-- @todo remove style! -->
    </template>

    <template v-slot:[`item.created_at`]="{ item }">
      {{ datetime.formatToLocal(item.created_at) }}
    </template>

    <template v-slot:[`item.updated_at`]="{ item }">
      {{ datetime.formatToLocal(item.updated_at) }}
    </template>

    <!-- reload -->
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Recharger
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import ClientDataService from '@/services/ClientDataService.js';
import datetime from '@/utils/date.js';

export default {
  data: function() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
          width: '150px',
          cellClass: 'ta-clients-redirects-col text-truncate'
        },
        { text: 'Nom', value: 'name', width: '150px' },
        //{ text: 'Description', value: 'description', filterable: false },
        { text: 'Utilisateur', value: 'user_id', filterable: true },
        { text: 'Redirections', value: 'redirect_uri', width: '350px', cellClass: 'ta-clients-redirects-col text-truncate', filterable: false },
        { text: 'Actif', value: 'is_enabled', filterable: true },
        { text: 'Confidentiel', value: 'is_confidential', filterable: true },
        { text: 'Création', value: 'created_at', filterable: false },
        //{ text: 'Mise à jour', value: 'updated_at', filterable: false }
      ],
      loading: true,
      options: {},
      clients: [],
      sortBy: 'name',
      sortDesc: false,
      //page: 0,
      totalItems: 0,
      totalPages: 0,
      datetime: datetime
    };
  },

  watch: {
    // reactive pagination
    options: {
      handler() {
        this.readDataFromAPI();
      },
      deep: true,
    }
  },

  // this will trigger in the onReady State
  mounted: function() {
    //this.initialize();
  },

  methods: {
    initialize: function() {
      this.readDataFromAPI();
    },
    // reading data from API method.
    readDataFromAPI: function() {
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      console.log(this.options);
      const pageNumber = page - 1;
      const offset = pageNumber * itemsPerPage;

      var sortQuery = '';
      if (sortBy.length > 0) {
        // qsort=+author,-datepublished
        var sortOrderSymbol = '';
        for (var i = 0; i < sortBy.length; i++) {
          sortOrderSymbol = sortDesc[i] ? '-' : '+';
          sortQuery += sortOrderSymbol + sortBy[i] + ',';
        }
        sortQuery = sortQuery.slice(0, -1);
      }

      let data = {
        limit: itemsPerPage,
        offset: offset
      };

      if (sortQuery) {
        data.sort = sortQuery;
      }

      ClientDataService.getAll(data)
        .then((response) => {
          // then injecting the result to datatable parameters.
          this.loading = false;
          this.clients = response.data;
          this.totalItems = parseInt(response.headers['x-total-count']);
          this.totalPages = parseInt(response.headers['x-total-pages']);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}
</script>

<style>
td.ta-clients-redirects-col {
  max-width: 250px;
}
</style>