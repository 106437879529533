<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="allow" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Autorisation</h3>
            </v-card-title>
            <v-card-text>

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <p>L'application <strong>{{applicationName}}</strong> a besoin d'accéder aux informations suivantes :</p>
              
              <v-list dense>
                <v-list-item v-for="scope in scopes" :key="scope.id" two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{scope.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{scope.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

<!--
              <p>L'application <strong>{{applicationName}}</strong> a besoin d'accéder aux informations suivantes :</p>

              <v-list>
                <v-list-item v-for="scope in requiredScopes" :key="scope.id">
                  {{scope.name}}
                </v-list-item>
              </v-list>

              <p>L'application peut déjà accéder aux informations suivantes :</p>

              <v-list>
                <v-list-item v-for="scope in allowedScopes" :key="scope.id">
                  {{scope.name}}
                </v-list-item>
              </v-list>
-->

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="deny" color="error" text>
                Refuser
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success" variant="elevated">
                Autoriser
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ScopeDataService from '@/services/ScopeDataService';
//import UserDataService from '@/services/UserDataService';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';

export default {
  setup: function() {
    const authStore = useAuthStore();
    const userStore = useUserStore();

    return { authStore, userStore };
  },

  data: function() {
    return {
      error: false,
      errorText: '',
      show: false,
      applicationID: '',
      applicationName: 'SomeApp',
      requiredScopes: [
        {
          id: 1,
          name: 'test'
        }
      ],
      allowedScopes: [
        {
          id: 3,
          name: 'bar'
        }
      ],
      // @todo populate previous array with data from provider...
      scopes: [],
      scopeData: null
    };
  },

  /*watch: {
    'userStore.getUserProfile': function(value) {
      // get current user identifier
      const userID = this.userStore.getUserProfile.id;
      console.log('from watch', JSON.stringify(value));

      // get application approval status (if exist)
      // @todo problem: initialize auto-login, then reload consent page and redirect to login page...
      UserDataService.getClientAuthorization(userID, this.applicationID)
        .then((response) => {
          if (response.data.authorized) {
            this.redirect();
          }
        }).catch((err) => {
          console.error(err);
        });
    }
  },*/

  mounted: function() {
    console.log('[consent page] query string:', this.$route.query);

    let requiredScopes;

    if (this.$route.query.scopes != null) {
      const scopesStr = this.$route.query.scopes;
      requiredScopes = scopesStr.trim().split(/\s+/);
      console.log('[consent page] scopes:', this.$route.query.scopes);
    }

    if (this.$route.query.client_id != null) {
      this.applicationID = this.$route.query.client_id;
      console.log('[consent page] app id:', this.$route.query.client_id);
    }

    if (this.$route.query.client_name != null) {
      this.applicationName = this.$route.query.client_name;
      console.log('[consent page] app name:', this.$route.query.client_name);
    }

    if (this.$route.query.redir != null) {
      console.log('[consent page] will redirect to:', this.$route.query.redir);
    }

    // get scopes data from server
    ScopeDataService.getAll()
      .then((response) => {
        this.scopeData = response.data;

        // update required scopes with data
        let updatedScopes = [];
        for (let i = 0; i < requiredScopes.length; i++) {
          let scopeID = requiredScopes[i];
          let idx = this.scopeData.findIndex(x => x.id === scopeID);
          updatedScopes.push(this.scopeData[idx]);
        }

        this.scopes = updatedScopes;
        console.log('updated scopes:', this.scopes);
      }).catch((err) => {
        console.error(err);
      });
  },

  methods: {
    redirect: function() {
      if (this.$route.query.redir != null) {
        window.location.href = this.$route.query.redir;
      } else {
        this.$router.push('/'); // @todo it is wrong! handle this with an error message
      }
    },

    updateAPI: function(approved) {
      // initialize error alert
      this.error = false;
      const self = this;

      // api call to set consent state, then redirect
      this.authStore.consentRequest({
          'approved': approved,
          'client_id': this.applicationID
        })
        .then(function() {
          self.redirect();
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },

    allow: function() {
      //this.$refs.form.validate();
      this.updateAPI(true);
    },

    deny: function() {
      //this.$refs.form.validate();
      this.updateAPI(false);
    }
  }
};
</script>